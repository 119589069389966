<template>
  <div>
    <div class="mb-main-content">
      <h1>{{ $t("page.profile.title") }}</h1>
      <div class="d-flex gap-3">
        <a :href="getBrandPreviewUrl('shortprofile')" class="btn btn-primary mb-preview-shortprofile"
          v-if="!app.isActiveMember" target="_blank">
          {{ $t("button.previewshortpage") }}
        </a>
        <a :href="getBrandPreviewUrl('fair')" class="btn btn-primary mb-preview-fair" target="_blank">
          {{ $t("button.previewbrandpage") }}
        </a>
        <router-link :to="computedNewFairFormUrl" class="btn btn-primary mb-preview-fair" v-if="app.isActiveMember">
          Zukünftige Messestand-Ansicht prüfen
        </router-link>
      </div>

      <BrandProfileImages />
      <BrandProfileVideos />
      <BrandProfileExperiences />
      <BrandProfileNews />
      <BrandProfileSettings />
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Vue from 'vue'
import Config from '@/config'

import BrandProfileImages from './Profile/2_Images'
import BrandProfileVideos from './Profile/3_Videos'
import BrandProfileExperiences from './Profile/4_Experiences'
import BrandProfileNews from './Profile/5_News'
import BrandProfileSettings from './Profile/6_Settings'

export default {
  name: 'Profile',
  components: {
    BrandProfileImages,
    BrandProfileVideos,
    BrandProfileExperiences,
    BrandProfileNews,
    BrandProfileSettings
  },
  props: [],
  data: function () {
    return {
      scrollEventLoaded: false,
      websiteUrl: Vue.Utils.trim(Config.WEBSITE_URL, '/') + '/'
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    computedNewFairFormUrl: function () {
      return '/neuer-messestand-2024/' + this.currentBrand.BrandID
    }
  },
  mounted: function () {
    this.loadScrollEvents()
  },
  methods: {
    getBrandPreviewUrl: function (preview) {
      let resUrl = this.websiteUrl
      if (this.currentBrand.ComParamOriginCountryID === 2) {
        resUrl = this.websiteUrl.replace('.de', '.at')
      } else if (this.currentBrand.ComParamOriginCountryID === 5) {
        resUrl = this.websiteUrl.replace('.de', '.ch')
      }

      return resUrl + this.currentBrand.BrandID + '?preview=' + preview
    },
    loadScrollEvents () {
      if (this.scrollEventLoaded === true) return

      $(window).scroll(() => {
        if (this.$route) {
          if (this.$route.path === '/profil') {
            const elementInView = $('.mb-main-content div.container[id]').filter(
              (index, elem) => {
                const docViewTop = $(window).scrollTop()
                const elemTop = $(elem).offset().top
                return elemTop + 200 >= docViewTop
              }
            )

            if (elementInView.length) {
              $('[data-scrollto]').removeClass('active')
              const elementId = '#' + $(elementInView).attr('id')
              $('.list-group')
                .find("[data-scrollto='" + elementId + "']")
                .addClass('active')
            }
          }
        }
      })
      this.scrollEventLoaded = true
    }
  }
}
</script>
